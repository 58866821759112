var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home", class: _vm.pageType }, [
    _c(
      "div",
      { staticClass: "home-container" },
      [
        !_vm.getIfLoadingUsersAreas && _vm.getUsersAreas.length
          ? _c(
              "BespokeInput",
              {
                staticClass: "home-header__search",
                attrs: { icon: require("@/assets/search.svg") },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              },
              [
                _c("span", { staticClass: "text-italic" }, [
                  _c("b", [_vm._v("Search")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-mid-grey" }, [
                    _vm._v("Spaces"),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.pendingInvites.length
          ? _c(
              "div",
              { staticClass: "home-pending-invites" },
              [
                _c("div", { staticClass: "home-header" }, [
                  _c("h1", { staticClass: "home-header__text" }, [
                    _vm._v(" My pending invitations "),
                    _c(
                      "span",
                      { staticClass: "text-mid-grey text-weight-normal" },
                      [_vm._v("(" + _vm._s(_vm.pendingInvites.length) + ")")]
                    ),
                  ]),
                ]),
                _c(
                  "slide-in-transition-group",
                  {
                    staticClass: "home-spaces",
                    attrs: {
                      total: _vm.pendingInvites.length,
                      blinkOut: true,
                      duration: 1000,
                    },
                  },
                  _vm._l(_vm.pendingInvites, function (space, index) {
                    return _c("SpaceListing", {
                      key: space.areaId,
                      style: { "--i": index },
                      attrs: { space: space, variant: "invite" },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "home-header" }, [
          _c(
            "h1",
            {
              staticClass: "home-header__text",
              class: { faded: _vm.spacesList.length === 0 },
            },
            [
              _vm._v(" Your Spaces collection "),
              _c("span", { staticClass: "text-mid-grey text-weight-normal" }, [
                _vm._v("(" + _vm._s(_vm.spacesList.length) + ")"),
              ]),
            ]
          ),
        ]),
        _c(
          "slide-in-transition-group",
          {
            staticClass: "home-spaces",
            attrs: {
              total: _vm.spacesList.length + 2 + _vm.skeletonSpacesNo,
              blinkOut: true,
              duration: 1000,
            },
          },
          [
            _vm._l(_vm.spacesList, function (space, index) {
              return _c("SpaceListing", {
                key: space.id,
                style: { "--i": index },
                attrs: { space: space },
              })
            }),
            !_vm.getIfLoadingUsersAreas && !_vm.spacesList.length && !_vm.search
              ? _c("div", { key: "info", staticClass: "home-spaces-info" }, [
                  _c("h2", [
                    _vm._v("Welcome to SPACEIN. "),
                    _c("br"),
                    _vm._v("This is where your Spaces live."),
                  ]),
                  _c("p", [
                    _vm._v(
                      " A Space is a visualised digital environment where you can connect with your peers. You can create multiple Spaces or join a Space created by other members. Come back, and connect anytime, anywhere. "
                    ),
                  ]),
                  _c("p", { staticClass: "grey" }, [
                    _vm._v(
                      " You currently don’t have a Space assigned to your account. Why not create your own? "
                    ),
                  ]),
                  _c("p", [_vm._v(" All Spaces are FREE!* ")]),
                  _c("span", { staticClass: "disclaimer" }, [
                    _vm._v(" *For up to 25 users per Space "),
                  ]),
                ])
              : _vm._e(),
            !_vm.getIfLoadingUsersAreas
              ? _c(
                  "div",
                  {
                    key: "create-space",
                    staticClass: "home-spaces-create",
                    style: { "--i": _vm.spacesList.length },
                    on: { click: _vm.goToCreateArea },
                  },
                  [
                    _c("img", {
                      staticClass: "home-spaces-create__icon",
                      attrs: {
                        src: require("@/assets/new-space-icon-gold.svg"),
                        alt: "",
                      },
                    }),
                    _c("div", [_vm._v("Create new Space")]),
                  ]
                )
              : _vm._e(),
            !_vm.getIfLoadingUsersAreas && !_vm.spacesList.length && _vm.search
              ? _c(
                  "div",
                  {
                    key: "no-space",
                    staticClass: "home-spaces-none",
                    style: { "--i": _vm.spacesList.length + 1 },
                    on: { click: _vm.goToCreateArea },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/not-found-area.svg") },
                    }),
                    _c("h2", [_vm._v("No Spaces found.")]),
                    _c("p", [
                      _vm._v("Why not create "),
                      _c("b", [_vm._v(_vm._s(_vm.search))]),
                      _vm._v(" Space?"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.getIfLoadingUsersAreas
              ? _vm._l(_vm.skeletonSpacesNo, function (skeleton, index) {
                  return _c(
                    "Skeleton",
                    {
                      key: skeleton,
                      style: {
                        "--i": _vm.spacesList.length + index + 2,
                      },
                      attrs: {
                        size: "310px 460px",
                        rounded: "",
                        gradient: "",
                        noverflow: "",
                      },
                    },
                    [
                      _c("Skeleton", {
                        attrs: { size: "100% 180px", shimmer: "" },
                      }),
                      _c("Skeleton", {
                        attrs: {
                          size: "120px",
                          circle: "",
                          absolute: "",
                          offsets: "100px 0 0 25px",
                        },
                      }),
                      _c("Skeleton", {
                        attrs: {
                          size: "20px 40px",
                          "rounded-full": "",
                          absolute: "",
                          offsets: "10px 10px 0",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { padding: "57px 25px 0" } },
                        [
                          _c("Skeleton", {
                            attrs: {
                              size: "70% 25px",
                              margin: "0 0 5px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                          _c("Skeleton", {
                            attrs: {
                              size: "50% 20px",
                              margin: "0 0 27px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                          _c("Skeleton", {
                            attrs: {
                              size: "60% 15px",
                              margin: "0 0 27px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                          _c("Skeleton", {
                            attrs: {
                              size: "100% 12px",
                              margin: "0 0 8px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                          _c("Skeleton", {
                            attrs: {
                              size: "100% 12px",
                              margin: "0 0 8px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                          _c("Skeleton", {
                            attrs: {
                              size: "100% 12px",
                              margin: "0 0 8px",
                              shimmer: "",
                              "rounded-sm": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }